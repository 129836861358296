import React, { Component } from "react"
import * as JsSearch from "js-search"
import StopWords from "./StopWords"

class SearchFAQ extends Component {

  nbResultPerPage = 10;

  state = {
    pages: [],
    genericblocks: [],
    search: [],
    searchResults: [],
    isLoading: true,
    isError: false,
    searchQuery: "",
    hasSearched: false,
    nbPage: 0,
    currentPage: 0,
    searchPage: []
  }

  /**
   * React lifecycle method to build the data
   */
  componentWillMount() {
    var pageToIndex = []
    //indexation FAQ
    let faqsPublished = this.props.allFaq;
    for (let i = 0; i < faqsPublished.length; i++) {
      let faq = faqsPublished[i];
      if (faq.published) {
        let url = '/faq/' + faq.url
        const toIndex = {
          _id: 'faq_' + faq.id,
          title: faq.title,
          url: url,
          description: faq.description,
          content: this.retrieveFaqContent(faq)
        }
        pageToIndex.push(toIndex);

        this.handleChange = this.handleChange.bind(this);
        if (this.props.search && this.props.search.recherche) {
          const searchQuery = this.props.search.recherche;
          this.setState({ hasSearched: false, searchQuery: searchQuery });
        }
      }
    }
    //liste de contenu a indexer
    this.setState({ pages: pageToIndex })
    this.handleChange = this.handleChange.bind(this);
    if (this.props.search) {
      const searchQuery = this.props.search;
      this.setState({ searchQuery: searchQuery });
    }
  }

  /**************************************************************************************
    * recuperation type de block : simpleblock 
    * --- A CREER PAR CHAQUE TYPE DE CONTENU---
    ***************************************************************************************/
  retrieveFaqContent(faq) {
    return ' ' + (faq.title ? faq.title : '')
      + ' ' + (faq.description ? faq.description : '')
      + ' ' + (faq.content ? faq.content : '');
  }

  /**
   * React lifecycle method to build the index
   */
  async componentDidMount() {
    this.rebuildIndex()
  }

  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const { pages } = this.state;
    const { searchQuery } = this.state;
    const dataToSearch = new JsSearch.Search("_id");
    /**
     *  defines a indexing strategy for the data
     * more more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("_id")

    /***************************************************************************************
    * --- STOP WORD TO EXCLUDE ---
    * Liste des mots à exclure dans StopWords.init (méthode statique)
    ***************************************************************************************/
    dataToSearch.tokenizer =
      new JsSearch.StopWordsTokenizer(
        new JsSearch.SimpleTokenizer());
    StopWords.init(JsSearch);

    /***************************************************************************************
    * --- CHAMPS A FAIRE INDEXER ---
    ***************************************************************************************/
    dataToSearch.addIndex("_id")
    dataToSearch.addIndex("url") // sets the index attribute for the data
    dataToSearch.addIndex("title") // sets the index attribute for the data
    dataToSearch.addIndex("description") // sets the index attribute for the data
    dataToSearch.addIndex("content") // sets the index attribute for the data
    dataToSearch.addDocuments(pages);
    this.setState({ search: dataToSearch, isLoading: false }, e => {
      if (this.props.search && this.props.search.recherche) {
        this.launchSearch(this.props.search.recherche);
      }
    })
  }

  resetQuery = e => {
    this.searchQuery = '';
    this.setState({ searchQuery: '', searchResults: [], searchPage: [], hasSearched: false, nbPage:0, currentPage:0})
  }

  launchSearch(searchQuery) {
    const { search, } = this.state;
    const queryResult = search.search(searchQuery);
    const nbPage = Math.ceil(queryResult.length / this.nbResultPerPage);
    const searchPage = queryResult.slice(0, this.nbResultPerPage);
    this.setState({ searchResults: queryResult, hasSearched: true, searchQuery: searchQuery, nbPage: nbPage, currentPage: 0, searchPage: searchPage })
  }

  loadPageResult(num) {
    const { searchResults } = this.state;
    if (searchResults) {
      const searchPage = searchResults.slice((num * this.nbResultPerPage), (num * this.nbResultPerPage) + this.nbResultPerPage);
      this.setState({ searchPage: searchPage, currentPage: num });
    }
  }

  previousPage() {
    const { currentPage } = this.state;
    this.loadPageResult(currentPage - 1);
  }

  nextPage() {
    const { currentPage } = this.state;
    this.loadPageResult(currentPage + 1);
  }

  //Mobile
  searchData = e => {
    e.preventDefault();
    this.launchSearch(e.target.value);
  }

  handleSubmit = e => {
    e.preventDefault()
  }

  handleSubmitMobile = e => {
    e.preventDefault()
    this.launchSearch(this.state.searchQuery);
  }

  //Desktop
  handleChange(event) {
    this.setState({ searchQuery: event.target.value });
    if (event.target.value === '') {
      this.resetQuery();
    }
  }

  searchDataDesktop = e => {
    this.launchSearch(this.state.searchQuery);
  }

  render() {
    const { pages, searchResults, searchQuery, hasSearched, searchPage, nbPage, currentPage } = this.state;
    const queryResults = searchQuery === "" ? pages : searchResults;
    console.log(Object.entries(searchQuery).length )
    return (
      <div>
        <div style={{ margin: "0 auto" }}>

          <form onSubmit={this.handleSubmit} className="faqArticleResultFormDesktop">
            <input type="text" value={Object.entries(searchQuery).length === 0 ? null : searchQuery} onChange={this.handleChange} placeholder="Saisissez un mot-clé ou une question" />
            <button onClick={this.searchDataDesktop} className="buttonSearch">Rechercher</button>
          </form>

          <form onSubmit={this.handleSubmitMobile} className="faqArticleResultFormMobile">
            <button className="fa fa-search"></button>
            <input id="formFaqSearch" name="faq-search" type="text" placeholder="Rechercher" value={Object.entries(searchQuery).length === 0 ? null : searchQuery} onChange={this.handleChange} />
            <button type="reset" className="icon-close" onClick={this.resetQuery}></button>
          </form>
          <p className="pageHeaderSubtitle">Exemples de recherche : « <a href="/faq?recherche=remboursement"><u>remboursement</u></a> », « <a href="/faq?recherche=adhésion"><u>adhésion</u></a> », « <a href="/faq?recherche=garantie"><u>garantie</u></a> », « <a href="/faq?recherche=contrat"><u>contrat</u></a> » ...</p>

          <div className={searchQuery? "container " :""}>
            <div  hidden={!hasSearched}>
              Nombre de résultats : {searchQuery !== '' ? queryResults.length : 0}
            </div>
            <div className="row" >
              {searchPage.map(item => {
                if (searchQuery !== '') {
                  return (
                    <div className="col-12" key={`row_${item._id}`}>
                      <div className="actualitesArticles">
                        <h4 className="actualitesArticlesTitle"><a href={item.url}>{item.title}</a></h4>
                        <p className="actualitesArticlesPublication">{item.description}</p>
                        <a className="actualitesArticlesLink" href={item.url}> Lire la suite</a>
                      </div>
                      <hr className="actualitesHr" />
                    </div>
                  )
                } else {
                  return;
                }
              })}
            </div>
            <form onSubmit={this.handleSubmit}  >
              <div className="recherche">
                <ul className="recherchePagination">
                  <li key="prev" className="recherchePaginationItem">
                    {currentPage !== 0 ? (
                      <a onClick={(e) => this.previousPage(e)}>←</a>
                    ) : (<></>)}
                  </li>
                  {Array.from({ length: nbPage }, (_, i) => (
                    i === currentPage ?
                      (
                        <li key={i} className="recherchePaginationItem">
                          <a className="active" onClick={(e) => this.loadPageResult(i, e)}>{i + 1}</a>
                        </li>
                      ) : (
                        <li key={i} className="recherchePaginationItem">
                          <a onClick={(e) => this.loadPageResult(i, e)}>{i + 1}</a>
                        </li>
                      )
                  ))}
                  <li key="next" className="recherchePaginationItem">
                    {(currentPage !== (nbPage - 1) && nbPage !== 0) ? (
                      <a onClick={(e) => this.nextPage(e)}>→</a>
                    ) : (<></>)}
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      </div >
    )
  }
}

export default SearchFAQ;
