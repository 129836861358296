import React from "react";
import Layout from "../components/layout";
import SearchFaq from "../components/SearchFaqContainer";
import withLocation from "../components/withLocation";
import Accordion from "../components/faqAccordion";
import _ from "lodash"


class FaqByCategoryTemplate extends React.Component {
    data;
    frequentlyAskedQuestions;
    active;
    allFaq;
    search;
    category;
    territeoUrl;
    first;

    constructor(props) {
        super(props);
        this.search = props.search;
        this.state = {
            activeKey: null
        }
        this.onClick = this.onClick.bind(this);
    }


    onClick(id) {
        if (this.state.activeKey) {
            id = null;
        }
        this.setState({
            activeKey: id
        });
    }
    isVideo(faq) {
        return _.includes(faq.content, '<video');
    }

    render() {
        this.data = this.props.pageContext
        this.faqs = this.data.faqs
        console.log(this.faqs)
        this.category = this.data.category
        if (this.props.search.recherche) {
            this.search = this.props.search.recherche;
        }

        let hidden = {
            display: 'none'
        }
        return (
            <Layout categories={this.data.faqCategories} territeoUrl={this.data.territeoUrl}>
                <section className="pageHeader">
                    <div>
                        <h1 className="pageHeaderTitle">{this.category.title}</h1>
                    </div>
                    <div style={hidden}>
                        <p className="pageHeaderSubtitle">Vous avez des interrogations ?</p>
                        <SearchFaq allFaq={this.faqs} search={this.search}></SearchFaq>
                    </div>
                </section>

                <section className="faq">
                    <div key="faq" className="faq-container">
                        <Accordion allowMultipleOpen>
                            {
                                _.sortBy(this.faqs,"position")
                                .map(faq => {
                                    return (
                                        <div className="mb-0" label={faq.title} isVideo={this.isVideo(faq)} isOpen={this.isVideo(faq)} key={faq.id} faqId={faq.id} content ={faq.content}>
                                        </div>
                                    )
                                })
                            }
                        </Accordion>
                    </div>
                </section>
            </Layout >
        )
    }
}
export default withLocation(FaqByCategoryTemplate)
