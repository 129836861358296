import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Card } from "react-bootstrap";


class AccordionSection extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Object),
        isOpen: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        isVideo: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        faqId: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired
    };

    onClick = () => {
        this.props.onClick(this.props.label);
    };

    render() {
        const { onClick, props: { isOpen, label, isVideo,faqId,content } } = this;

        return (
                <div key={faqId} className="accordion" onClick={onClick} style={{ cursor: "pointer" }}>
                    <Card key={faqId}>
                        <Card.Header>
                            <h2 className="mb-0">
                                <button className={classNames(
                                    "btn btn-link question",
                                    { collapsed: !isOpen }
                                )} type="button" aria-expanded="false" aria-controls={"collapse-" + faqId}>
                                    {isVideo && (<><i className="menu-icon fa fa-video"></i>&nbsp;</>)}
                                    {label}
                                </button>
                            </h2>
                        </Card.Header>
                        {isOpen && (
                            <Card.Body className="answer" dangerouslySetInnerHTML={{ __html: content }}>
                            </Card.Body>
                        )}

                    </Card>
                </div>           
        );
    }
}

export default AccordionSection;