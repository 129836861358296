class StopWords {

    static init(JsSearch) {
        JsSearch.StopWordsMap.alors = true;
        JsSearch.StopWordsMap.au = true;
        JsSearch.StopWordsMap.aucuns = true;
        JsSearch.StopWordsMap.aussi = true;
        JsSearch.StopWordsMap.autre = true;
        JsSearch.StopWordsMap.avant = true;
        JsSearch.StopWordsMap.avec = true;
        JsSearch.StopWordsMap.avoir = true;
        JsSearch.StopWordsMap.bon = true;
        JsSearch.StopWordsMap.car = true;
        JsSearch.StopWordsMap.ce = true;
        JsSearch.StopWordsMap.cela = true;
        JsSearch.StopWordsMap.ces = true;
        JsSearch.StopWordsMap.ceux = true;
        JsSearch.StopWordsMap.chaque = true;
        JsSearch.StopWordsMap.ci = true;
        JsSearch.StopWordsMap.comme = true;
        JsSearch.StopWordsMap.comment = true;
        JsSearch.StopWordsMap.dans = true;
        JsSearch.StopWordsMap.des = true;
        JsSearch.StopWordsMap.du = true;
        JsSearch.StopWordsMap.dedans = true;
        JsSearch.StopWordsMap.dehors = true;
        JsSearch.StopWordsMap.depuis = true;
        JsSearch.StopWordsMap.devrait = true;
        JsSearch.StopWordsMap.doit = true;
        JsSearch.StopWordsMap.donc = true;
        JsSearch.StopWordsMap.dos = true;
        JsSearch.StopWordsMap.début = true;
        JsSearch.StopWordsMap.elle = true;
        JsSearch.StopWordsMap.elles = true;
        JsSearch.StopWordsMap.en = true;
        JsSearch.StopWordsMap.encore = true;
        JsSearch.StopWordsMap.essai = true;
        JsSearch.StopWordsMap.est = true;
        JsSearch.StopWordsMap.et = true;
        JsSearch.StopWordsMap.eu = true;
        JsSearch.StopWordsMap.fait = true;
        JsSearch.StopWordsMap.faites = true;
        JsSearch.StopWordsMap.fois = true;
        JsSearch.StopWordsMap.font = true;
        JsSearch.StopWordsMap.hors = true;
        JsSearch.StopWordsMap.ici = true;
        JsSearch.StopWordsMap.il = true;
        JsSearch.StopWordsMap.ils = true;
        JsSearch.StopWordsMap.je = true;
        JsSearch.StopWordsMap.juste = true;
        JsSearch.StopWordsMap.la = true;
        JsSearch.StopWordsMap.le = true;
        JsSearch.StopWordsMap.les = true;
        JsSearch.StopWordsMap.leur = true;
        JsSearch.StopWordsMap.là = true;
        JsSearch.StopWordsMap.ma = true;
        JsSearch.StopWordsMap.maintenant = true;
        JsSearch.StopWordsMap.mais = true;
        JsSearch.StopWordsMap.mes = true;
        JsSearch.StopWordsMap.mine = true;
        JsSearch.StopWordsMap.moins = true;
        JsSearch.StopWordsMap.mon = true;
        JsSearch.StopWordsMap.mot = true;
        JsSearch.StopWordsMap.même = true;
        JsSearch.StopWordsMap.ni = true;
        JsSearch.StopWordsMap.nommés = true;
        JsSearch.StopWordsMap.notre = true;
        JsSearch.StopWordsMap.nous = true;
        JsSearch.StopWordsMap.ou = true;
        JsSearch.StopWordsMap.où = true;
        JsSearch.StopWordsMap.par = true;
        JsSearch.StopWordsMap.parce = true;
        JsSearch.StopWordsMap.pas = true;
        JsSearch.StopWordsMap.peut = true;
        JsSearch.StopWordsMap.peu = true;
        JsSearch.StopWordsMap.plupart = true;
        JsSearch.StopWordsMap.pour = true;
        JsSearch.StopWordsMap.pourquoi = true;
        JsSearch.StopWordsMap.quand = true;
        JsSearch.StopWordsMap.que = true;
        JsSearch.StopWordsMap.quel = true;
        JsSearch.StopWordsMap.quelle = true;
        JsSearch.StopWordsMap.quelles = true;
        JsSearch.StopWordsMap.quels = true;
        JsSearch.StopWordsMap.qui = true;
        JsSearch.StopWordsMap.sa = true;
        JsSearch.StopWordsMap.sans = true;
        JsSearch.StopWordsMap.ses = true;
        JsSearch.StopWordsMap.seulement = true;
        JsSearch.StopWordsMap.si = true;
        JsSearch.StopWordsMap.sien = true;
        JsSearch.StopWordsMap.son = true;
        JsSearch.StopWordsMap.sont = true;
        JsSearch.StopWordsMap.sous = true;
        JsSearch.StopWordsMap.soyez = true;
        JsSearch.StopWordsMap.sujet = true;
        JsSearch.StopWordsMap.sur = true;
        JsSearch.StopWordsMap.ta = true;
        JsSearch.StopWordsMap.tandis = true;
        JsSearch.StopWordsMap.tellement = true;
        JsSearch.StopWordsMap.tels = true;
        JsSearch.StopWordsMap.tes = true;
        JsSearch.StopWordsMap.ton = true;
        JsSearch.StopWordsMap.tous = true;
        JsSearch.StopWordsMap.tout = true;
        JsSearch.StopWordsMap.trop = true;
        JsSearch.StopWordsMap.très = true;
        JsSearch.StopWordsMap.tu = true;
        JsSearch.StopWordsMap.voient = true;
        JsSearch.StopWordsMap.vont = true;
        JsSearch.StopWordsMap.votre = true;
        JsSearch.StopWordsMap.vous = true;
        JsSearch.StopWordsMap.vu = true;
        JsSearch.StopWordsMap.ça = true;
        JsSearch.StopWordsMap.étaient = true;
        JsSearch.StopWordsMap.état = true;
        JsSearch.StopWordsMap.étions = true;
        JsSearch.StopWordsMap.été = true;
        JsSearch.StopWordsMap.être = true;
        JsSearch.StopWordsMap.sécu = true;
    }

}

export default StopWords